<template>
    <nav aria-label="Page navigation example">
        <ul class="pagination">
            <li v-if="min > 1" class="page-item">
                <a @click="selectPage($event, 1)" class="page-link" href="javascript:;" aria-label="Previous">
                    <span aria-hidden="true">Trang đầu</span>
                </a>
            </li>
            <template v-for="link in links" >
                <li class="page-item" :class="link.active ? 'active' : ''" :key="link.index">
                    <a @click="selectPage($event, link.index)" class="page-link" :href="link.link">{{ link.index }}</a></li>
            </template>
            <li v-if="max < total_pages" class="page-item">
                <a @click="selectPage($event, total_pages)" class="page-link" href="javascript:;" aria-label="Previous">
                    <span aria-hidden="true">Trang cuối</span>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: "com-pagination",
        data() {
            return {
                max: 4,
                min: 2,
                mid: 3,
                c: 1
            }
        },
        methods: {
            selectPage(e, page) {
                e.preventDefault();
                this.$emit('select_page', page)
                this.$set(this, 'c', page);

            }
        },
        computed: {
            links() {
                var self = this;

                var url = self.base_link;
                if (url.indexOf('?') < 0) {
                    url += "?";
                }
                var output = [];
                for (var i = self.min; i <= self.max; i ++) {
                    output.push({
                        index: i,
                        link: `${url}&${self.name_query}=${i}`,
                        active: (i === self.c)
                    });
                }

                return output;
            }
        },

        watch: {
            c: function () {
                var self = this;
                var toMax = Math.ceil(self.max_link / 2);
                self.max = self.c + toMax;
                self.min = self.c - toMax;

                if (self.max > self.total_pages) {
                    self.min = self.min - (self.max - self.total_pages);
                    self.max = self.total_pages;
                }

                if (self.min <= 0) {
                    self.max = self.max + (0 - self.min);
                    self.min = 1;
                }

                if (self.max > self.total_pages) {
                    self.max = self.total_pages;
                }
            }
        },

        updated(){
            this.$set(this, 'c', Number(this.current_page) ? this.current_page : 1);
        },
        created(){
            this.$set(this, 'c', Number(this.current_page) ? this.current_page : 1);

            var self = this;
            var toMax = Math.ceil(self.max_link / 2);
            self.max = self.c + toMax;
            self.min = self.c - toMax;

            if (self.max > self.total_pages) {
                self.min = self.min - (self.max - self.total_pages);
                self.max = self.total_pages;
            }

            if (self.min <= 0) {
                self.max = self.max + (0 - self.min);
                self.min = 1;
            }

            if (self.max > self.total_pages) {
                self.max = self.total_pages;
            }
        },

        props: {
            total_pages: {
                type: Number,
                default: 10
            },
            current_page: {
                type: Number,
                default: 9
            },
            base_link: {
                type: String,
                default: "/?"
            },
            max_link: {
                type: Number,
                default: 6
            },
            name_query: {
                type: String,
                default: "page"
            }
        }
    }
</script>